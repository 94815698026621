/* latin */
@font-face {
  font-family: 'Delius';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Delius.woff2') format('woff2');
}

@font-face {
  font-family: "Open Sans";
  font-display: swap;
  src: url('../fonts/OpenSans.woff2') format('woff2');
}
