// Place all the styles related to the events controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/


.background-white {
  background-color: $white;
}

#events-menu {
  position: relative;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 33.85%;

  @include breakpoint(small down) {
    background-image: none;
    padding-top: 0;
    height: 200px;
  }
  -webkit-transition: all 0.4s ease-in;
  -moz-transition: all 0.4s ease-in;
  -o-transition: all 0.4s ease-in;
  -ms-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  &.alle-termine {
    background-image: url('../images/static/header/alle-termine.jpg');
    @include breakpoint(small down) {
      background-image: none;
    }
  }
  &.familien-konzerte {
    background-image: url('../images/static/header/familien-konzerte.jpg');
    @include breakpoint(small down) {
      background-image: none;
    }
  }
  &.kindergarten-konzerte {
    background-image: url('../images/static/header/kindergarten-konzerte.jpg');
    @include breakpoint(small down) {
      background-image: none;
    }
  }
  &.gastspiele {
    background-image: url('../images/static/header/gastspiele.jpg');
    @include breakpoint(small down) {
      background-image: none;
    }
  }
  &.workshops {
    background-image: url('../images/static/header/workshops.jpg');
    @include breakpoint(small down) {
      background-image: none;
    }
  }
  &.kita-konzerte {
    background-image: url('../images/static/header/mini-musik-in-kitas.jpg');
    @include breakpoint(small down) {
      background-image: none;
    }
  }
  .grid-container {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    @include breakpoint(small down) {
      display: initial;
    }
  }

}

.grid-container.event-info {
  @include breakpoint(small down) {
    .grid-x {
      padding-right: 1rem;
      padding-left: 1rem;
      @include breakpoint(small down) {
        padding-right: 2rem;
        padding-left: 2rem;
      }
    }
    padding-right: 0;
    padding-left: 0;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .accordion-pages {
    padding: 0;
  }

}

#events-menu:after {
  content: url('../images/static/header/alle-termine.jpg') url('../images/static/header/gastspiele.jpg') url('../images/static/header/familien-konzerte.jpg') url('../images/static/header/kindergarten-konzerte.jpg') url('../images/static/header/workshops.jpg');
  display: none;
}

#event-tabs {
  background-color: transparent;

  ul {
    width: 1200px;
    list-style-type: none;
    @include breakpoint(small down) {
      width: 100%;
    }
  }

  li {
    background-color: $light-gray;
    float: left;
    margin: 0 1px;
    padding: 0;
    @include breakpoint(small down) {
      height: 2.5rem;
      margin: 0;
      padding: 0;
      width: 100.0%;
      a {
        width: 100.0%;
      }
    }
    &:first-child {
      background-color: $primary-color;
      margin-left: 0;
      a {
        color: $white;
        width: 189px;
        @include breakpoint(small down) {
          width: 100%;
        }
      }
      &.is-active {
        background-color: $primary-color;
      }
    }
    &:last-child {
      background-color: scale-color($medium-gray, $lightness: +50%);
      margin-right: 0;
      a {
        width: 189px;
        @include breakpoint(small down) {
          width: 100%;
        }
      }
    }
    &.is-active {
      background-color: $white;
    }
    a {
      color: $dark-gray;
      // width: 100%;
      width: 188px;
      height: 6.5rem;
      position: relative;
      background-color: transparent;
      @include breakpoint(large down) {
        width: 100%;
      }
      h2 {
        font-size: 22px;
        line-height: 1.2;
        @include breakpoint(large down) {
          font-size: 24px;
        }
        @include breakpoint(medium down) {
          font-size: 20px;
        }
        @include breakpoint(small down) {
          font-size: 16px;
        }
      }
      &.stern {
        background-image: url('../images/static/layout/stern.png');
        background-position: right top;
        background-repeat: no-repeat;
        background-size: 113px 113px;
        @include breakpoint(small down) {
          background-position: 95% top;
          background-size: 40px 40px;
        }
      }
    }
  }
}


.events {
  .event {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    background-color: $light-gray !important;
    margin: 30px 0;
    .event-image {
      img {
        height: 370px;
        max-height: 370px;
        object-fit: cover;
        @include breakpoint(small down) {
          height: auto;
          max-height: none;
          width: 100%;
        }
      }
    }
    .event-description {
      display: flex;           /* establish flex container */
      flex-direction: column;  /* stack flex items vertically */
      position: relative;
      padding: 1rem;
      h6 {
        margin-bottom: 0;
      }
      h2 {
        display: inline;
      }
      h5 {
        display: inline;
      }
      .stern {
        position: absolute;
        left: 100%;
        top: 0%;
        transform: translate(-115px, 0px);
        width: 113px;
        height: 113px;
        z-index: 2;
        background-color: transparent;
      }
      .big-day-number {
        position: absolute;
        left: 100%;
        top: 100%;
        transform: translate(-175px, -78px);
        h4 {
          color: rgba(80, 80, 80, 0.3);
          font-family: 'Open Sans', sans-serif;
          font-weight: 800;
          font-size: 9rem;
          letter-spacing: -0.5rem;
          line-height: 0;
          // @include breakpoint(small down) {
          //   font-size: 8rem;
          // }
        }
      }
      .grid-y {
        height: 325px;
        @include breakpoint(small down) {
          height: 100%;
        }
      }
    }
    .event-label {
      padding: 0;
      margin: 0;
    }
    .event-content {
      padding: 0;
      margin: 0;
      .hide-for-medium {
        display: inline;
      }
    }
    .fa-long-arrow-alt-right {
      vertical-align: middle;
      font-size: 16px;
    }
  }
}
