@mixin spacing-utilities(){
  $spacing-settings: (
    'spaces'         : (
      'margin'      : 'm',
      'padding'     : 'p'
    ),
    'positions'      : (
      'top'         : 't',
      'bottom'      : 'b',
      'right'       : 'r',
      'left'        : 'l',
    ),
    'direction'      : (
      'vertical'     : 'v',
      'horizontal'   : 'h',
    ),
  );

  @each $space-key, $space in map_get($spacing-settings, 'spaces'){
    @each $position-key, $position in map_get($spacing-settings, 'positions') {
      $ctr: 0;
      @while $ctr <= 4 {
        @if $ctr == 4 {
          .#{$space}-#{$position}-auto {
            #{$space-key}-#{$position-key}: auto !important;
          }
        }
        @else {
          .#{$space}-#{$position}-#{$ctr} {
            #{$space-key}-#{$position-key}: #{$ctr*15}px !important;
          }
        }
        $ctr: $ctr + 1;
      }
    }
    @each $direction-key, $direction in map_get($spacing-settings, 'direction') {
      $ctr: 0;
      @while $ctr <= 4 {
        @if $ctr == 4 {
          .#{$space}-#{$direction}-auto {
            @if $direction == 'v' {
              #{$space-key}-top: auto !important;
              #{$space-key}-bottom: auto !important;
            }
            @else {
              #{$space-key}-left: auto !important;
              #{$space-key}-right: auto !important;
            }
          }
        }
        @else {
          .#{$space}-#{$direction}-#{$ctr} {
            @if $direction == 'v' {
              #{$space-key}-top: #{$ctr*15}px !important;
              #{$space-key}-bottom: #{$ctr*15}px !important;
            }
            @else {
              #{$space-key}-left: #{$ctr*15}px !important;
              #{$space-key}-right: #{$ctr*15}px !important;
            }
          }
        }
        $ctr: $ctr + 1;
      }
    }
  }
}