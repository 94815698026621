@import 'settings';

.team,
.unterricht {
  .grid-x {
    .media-object {
      background-color: $light-gray !important;
      border: none;
      margin-bottom: 0;
      .media-object-section {
        padding-right: 0;
        .team-image,
        .unterricht-image {
          width: 100%;
          img {
            max-height: 100%;
          }
        }
        .team-description,
        .unterricht-description {
          padding: 1em 2em;
          h6 {
            margin: 0;
          }
          h2 {
            margin-bottom: 0.5rem;
          }
          p {
            font-size: 1.2rem;
            line-height: 1.2;
            margin: 0;
            word-wrap: anywhere;
          }
        }
      }
    }
  }
}

.full-on-small-only {
  @include breakpoint(small down) {
    padding-right: 0 !important;
    padding-left: 0 !important;
    max-width: 100% !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.accordion-pages {
  @include accordion-container;
  padding: 2em 2em;
  li.accordion-item {
    @include accordion-item;
    margin: 0 0 0.2em 0;
    background-color: none;
    a.accordion-title {
      padding: 0.75em 1em;
      color: $dark-gray;
      background-color: $light-gray;
      font-weight: 400;
      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f078';
        font-weight: 900;
      }
    }
    &:hover a.accordion-title {
      background-color: $primary-color;
      color: $white;
    }
    &.is-active a.accordion-title {
      background-color: $primary-color;
      color: $white;
      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f077';
        font-weight: 900;
      }
    }
  }
  li.accordion-item:last-child {
    margin: 0;
  }
  .accordion-content {
    margin-bottom: 1.5em;
    img {
      margin-bottom: 1.5em;
    }
    .press-item {
      h2 {
        margin-bottom: 0;
      }
      margin-bottom: 1.5em;
    }
  }
}

.referenzen {
  table {
    margin: 0;
    padding: 0;
  }
  tr {
    margin: 0;
    padding: 0;
  }
  td.referenz-logo {
    margin: 0;
    padding: 0;
    width: 50%;
  }
  td.referenz-link {
    margin: 0;
    padding: 0;
    vertical-align: middle;
    h5 {
      margin: 0;
      padding: 0;
      margin-left: 2em;
    }
  }
}
