.blog-post {
  background-color: $light-gray !important;
  border: none;
  .cell {
    h6 {
    }
    .blog-post-image {
      img.layout-1 {
        padding-left: 1rem;
        float: right;
        width: 45%;
        max-width: 100%;
      }
    }
    .blog-post-description {
      padding: 0;
      p {
        line-height: 1.4;
      }
      ul {
        list-style: none;
        li {
          margin: 0 0 0.5rem 0rem;
        }
        li:before {
          content: '\f111'; /* FontAwesome Unicode */
          font-family: 'Font Awesome 5 Free';
          font-size: 0.4em;
          display: inline-block;
          vertical-align: middle;
          color: lighten($primary-color, 20%);
          font-weight: 700;
          margin-left: -2.6em; /* same as padding-left set on li */
          margin-right: 1.5em; /* same as padding-left set on li */
          margin-top: -0.3em; /* same as padding-left set on li */
        }
      }
    }
  }
}


#tag-select-buttons {
  background-color: $light-gray;
  .grid-x {
    padding: 30px 0;
    .cell {
      margin-bottom: 15px;
    }
    .tag-select-button {
      color: $dark-gray;
      background-color: lighten($medium-gray,50%);
      width: 100%;
      text-align: left;
      font-family: $body-font-family;
      font-weight: 300;
      font-size: 1.2rem;
      padding: 0.5rem 0.75rem;
      border: none;
      a {
        color: $secondary-color;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }
}
