.cookies-bar {
  position: sticky;
  bottom: 0;

  padding: 1rem 0;
  background-color: map-get($foundation-palette, secondary);
  color: lighten(map-get($foundation-palette, secondary), 50%);

  .btn {
    display: block;
    border: none;
    background-color: lighten(map-get($foundation-palette, secondary), 50%);
    color: map-get($foundation-palette, secondary);
    padding: 5px;
    margin-bottom: 5px;
    text-align: center;
    text-decoration: none;

    &.accept:hover {
      background-color: $primary-color;
    }
    &.reject:hover {
      background-color: $primary-color;
    }
  }
}