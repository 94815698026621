@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

#page-kater-matze {
  p {
    font-weight: 500;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
  .top-bar {
    .top-bar-left {
      width: 225px;
      max-width: 225px;
      @include breakpoint(medium down) {
        display: block;
      }
    }
    .top-bar-right {
      padding: 56px 0 0 0;
      height: 100%;
      @include breakpoint(medium down) {
        display: none;
      }
    }
  }
}

#orbit-kater-matze {
  .orbit-container {
    margin: 20px 20px;
    top: 20px;
    @include breakpoint(medium down) {
      margin: 10px 10px;
      top: 10px;
    }
  }
  figure.orbit-figure {
    img {
      width: 100%;
    }
  }

  .orbit-previous {
    left: 5%;
    color: #fefefebb;
  }

  .orbit-next {
    right: 5%;
    color: #fefefebb;
  }

  .orbit-controls {
    .fa-chevron-left,
    .fa-chevron-right {
      scale: 3;
      @include breakpoint(medium down) {
        scale: 2;
      }
      @include breakpoint(small down) {
        scale: 1;
      }
    }
  }

  .orbit-bullets {
    position: relative;
    margin-top: -1.65rem;
    margin-bottom: 0.8rem;
    text-align: center;
    z-index: 1000;
    @include breakpoint(medium down) {
      margin-top: 0;
      button {
        width: 0.6rem;
        height: 0.6rem;
      }
    }

  }

  .callout {
    h4 {
      @include breakpoint(medium down) {
        font-size: 0.9em;
      }
    }

    h6,
    .fas {
      @include breakpoint(medium down) {
        font-size: 0.8em;
      }
    }

    position: absolute;
    width: 50%;
    right: 7%;
    bottom: 10%;
    border: none;
    background: #fefefebb;

    @include breakpoint(medium down) {
      width: 100%;
      height: 25%;
      max-height: 25%;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      text-align: center;
    }
  }
}

#matze-intro{
  background-color: #f2f2f2;
}


// Musiker Texte
#matze-band-text {
  p {
    font-weight: 600 !important;
    margin: 0;
    em {
      // font-size: 28 !important;
      font-weight: 800;
      font-style: italic;
    }
  }
  .musician-profile {
    .cell:first-child {
      display: flex;
      justify-content: right;
      align-items: right;
    }
    .thumbnail {
      &.tatze {
        filter: drop-shadow(3px 3px 3px #003366);
        padding-right: 1rem;
        width: 60%;
        @include breakpoint(medium down) {
          width: 80%;
        }
      }
    }

  }
  .loudspeaker {
    img:hover {
      /* Start the shake animation and make the animation last for 0.5 seconds */
      animation: shake 0.5s;
      /* When the animation is finished, start again */
      animation-iteration-count: infinite;
    }
  }
}

#matze-band-picture {

  .member-1 {
    z-index: 110;
  }

  .member-2 {
    z-index: 100;
    transform: translateX(-10%) translateY(-15%) rotate(15deg);
  }

  .member-3 {
    z-index: 120;
    transform: translateX(17%) translateY(-12%) rotate(-15deg);
  }

  .member-4 {
    z-index: 130;
    transform: translateY(-12%);
  }

  .member-5 {
    z-index: 150;
    transform: translateX(5%) translateY(62%) rotate(-15deg);
  }

  .member-6 {
    z-index: 140;
    transform: translateX(-40%) translateY(-28%) rotate(15deg);
  }

  @for $i from 1 through 6 {
    .member-#{$i} {
      @include breakpoint(medium down) {
        transform: none;
      }
    }
  }

  .cell {
    position: relative;
    .caption-container {
      position: absolute;
      top: 15%;
      right: 15%;
      background-color: rgba(255, 255, 255, 0);
      color: rgb(255, 255, 255);
      opacity: 1;
      width: 150px;
      text-align: right;
      @include breakpoint(medium down) {
        top: auto;
        bottom: 15%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }

      .caption-content {
        padding: emCalc(10px);
        margin: 0;
      }
    }
  }
}


.background-water-1 {
  background: url(../images/static/kater-matze/hg-wasser-1.png) no-repeat center 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.background-water-2 {
  background: url(../images/static/kater-matze/hg-wasser-2.png) no-repeat center 0%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.background-blue-1 {
  background: url(../images/static/kater-matze/hg-blau-1.jpg) no-repeat center 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  @include breakpoint(medium down) {
    background: url(../images/static/kater-matze/hg-blau-1-mobile.jpg) no-repeat center 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.background-blue-2 {
  background: url(../images/static/kater-matze/hg-blau-2.jpg) no-repeat center 100%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  @include breakpoint(medium down) {
    background: url(../images/static/kater-matze/hg-blau-2-mobile.jpg) no-repeat center 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.background-gray-1 {
  background: url(../images/static/kater-matze/hg-grau-1.jpg) no-repeat center 30%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  @include breakpoint(medium down) {
    background: url(../images/static/kater-matze/hg-grau-1-mobile.jpg) no-repeat center 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}
.background-gray-2 {
  background: url(../images/static/kater-matze/hg-grau-2.jpg) no-repeat center center;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
  @include breakpoint(medium down) {
    background: url(../images/static/kater-matze/hg-grau-2-mobile.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

hr.shadow-down {
  border: none;
  height: 20px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: rgb(204,204,204);
  background: linear-gradient(180deg, #cccccc 0%, #f2f2f2 50%);
}

hr.shadow-up {
  border: none;
  height: 20px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: rgb(204,204,204);
  background: linear-gradient(0deg, #cccccc 0%, #f2f2f2 50%);
}

hr.shadow-up-2 {
  border: none;
  height: 20px;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: rgb(204,204,204);
  background: linear-gradient(0deg, #cccccc 0%, #fff 70%);
}


// Matze Figuren
// -------------

.cell {
  .bell {
    position: relative;
    width: 100%x; /* can be omitted for a regular non-lazy image */

    background: url(../images/static/kater-matze/klingel-off.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    img.image-hover {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      object-fit: contain;
      opacity: 0;
      transition: opacity .2s;
    }
    &:hover img.image-hover {
      opacity: 1;
      /* Start the shake animation and make the animation last for 0.5 seconds */
      animation: shake 0.5s;

      /* When the animation is finished, start again */
      animation-iteration-count: infinite;
    }

  }
  .bell:hover {
    background: url(../images/static/kater-matze/klingel-on.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

