@import 'settings';

.cr_button {
  display: inline-block;
  width: auto;
  white-space: nowrap;
  height: 32px;
  margin: 5px 5px 0 0;
  padding: 0 22px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  font-style: normal;
  font-size: 15px;
  line-height: 32px;
  cursor: pointer;
  border: 0;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  vertical-align: top;
  background-color: #333;
  color: #ffffff;
  &:hover {
    opacity: 0.7;
    filter: alpha(opacity = 70);
  }
}

input[type='checkbox'] {
  -moz-binding: none;
  -moz-box-sizing: border-box;
  background-color: -moz-field !important;
  border: 2px inset threedface !important;
  color: -moz-fieldtext !important;
  cursor: default;
  height: 1.5rem;
  padding: 0 !important;
  width: 1.5rem;
  margin: 0 5px 0 0;
  vertical-align:middle;
}

.submit_container {
  text-align: left;
}

.cr_ipe_item {
  padding: 0;
  margin: 0 0 10px 0;
  // &.inactive {
  //   display: none;
  // }
}

.imprint {
//  font-size: 0.8em;
  align: center;
}

.cr_captcha {
  padding-left: 130px;
}

.cr_error {
  font-size: 1.1em;
  padding: 10px;
}

.clever_form_error {
  background-color: #f99;
  color: #000;
  border: 1px solid #f22 !important;
}

.clever_form_note {
  margin: 26px 0 0 3px;
  position: absolute;
  display: inline;
  padding: 2px 4px;
  font-weight: bold;
  background-color: #f2ecb5;
  color: #000;
  font-size: 12px !important;
}

input#text956598.cr_number,
input#text956601.cr_number {
  display: inline;
  margin-right: 0.25rem;
}