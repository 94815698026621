// -----------------
// Background Helper
// -----------------

.background-pattern-orange {
  background: transparent url("../images/static/layout/mm_pattern_orange.png") left top repeat;
}

.background-pattern-gray {
  background: transparent url("../images/static/layout/mm_pattern_gray.png") left top repeat;
}

.background-orange {
  background-color: $primary-color;
}

.background-light-gray {
  background-color: $light-gray;
}


// ------------
// Color Helper
// ------------

.orange {
  color: $primary-color;
}

.white {
  color: $white;
}

.gray {
  color: $secondary-color;
}

.alert {
  color: $alert-color;
}

@include spacing-utilities;