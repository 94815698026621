// Place all the styles related to the programs controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: https://sass-lang.com/

@import 'settings';

// -------------
// program index
// -------------

#program-select-filters {
  background-color: $light-gray;
  .grid-x {
    padding: 30px 0;
    .cell {
      margin-bottom: 15px;
    }
    .filter-programs-button {
      color: $dark-gray;
      background-color: lighten($medium-gray,50%);
      width: 100%;
      text-align: left;
      font-family: $body-font-family;
      font-weight: 300;
      font-size: 1.2rem;
      padding: 0.5rem 0.75rem;
      border: none;
      &.hover {
        background-color: $white;
      }
      &:after {
        float: right;
        font-family: 'Font Awesome 5 Free';
        content: '\f078';
        font-weight:900;
      }
      &.hover:after {
        font-family: 'Font Awesome 5 Free';
        content: '\f077';
        font-weight:900;
      }
    }
    .filter-dropdown-pane {
      @include dropdown-container;
      background-color: $white;
      border: none;
      ul {
        margin-bottom: 0;
        list-style-position: inside;
        li {
          list-style: none;
          font-family: $body-font-family;
          font-weight: 300;
          font-size: 1.2rem;
          line-height: 1.5;
          margin-bottom: 0;
          &:hover,
          &.selected {
            font-weight: 600;
            cursor: pointer;
          }
        }
        li:before {
          color: $dark-gray;
          font-family: 'Font Awesome 5 Free';
          content: '\f111';
          margin: 0 5px 0 -20px;
          font-weight: 200;
        }
        li.selected:before,
        li:hover:before {
          color: $primary-color;
          font-family: 'Font Awesome 5 Free';
          content: '\f111';
          font-weight: 900;
        }
      }
      .button {
        color: $white;
        background-color: $primary-color;
        width: 100%;
        text-align: left;
        font-family: $body-font-family;
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
        padding: 0.5rem;
        border: none;
      }
    }
  }
}

#program-selected-filters {
  display: none;
  background-color: lighten($medium-gray,50%);
  #selected-filters {
    div {
      display: inline;
      padding: 0 0.5rem;
      margin: 0 1rem 0 0;
      font-family: $body-font-family;
      i:hover {
        color: $primary-color;
        cursor: pointer;
      }
    }
    #alle-loeschen {
      display: inline;
      padding: 0 0.5rem;
      margin: 0 1rem 0 0;
      font-family: $body-font-family;
      color: $light-gray;
      background-color: $dark-gray;
    }
  }
}

#programs {
  padding-top: 2rem;
}

// -------------
// program show
// -------------

ul.breadcrumbs {
  font-weight: 400;
  li {
    line-height: 1;
    margin-bottom: 0;
  }
}

.program-description {
  h2 {
    margin-bottom: 0;
  }
  img {
    width: 100%;
    margin: 2.5rem 0;
  }
}

.social-media-buttons {
  margin-bottom: 1.5rem;
  i.fab {
    color: lighten($medium-gray,50%);
    margin-right: 0.25rem;
  }
}

.program-pagination {
  margin-bottom: 2rem;
  a {
    color: $dark-gray;
    &:hover {
      color: $primary-color;
    }
  }
}
#media {
  color: $white;
}

.callout-info {
  @include callout-base;
  background-color: $light-gray;
  border: none;
  padding: 0.5rem 1rem;
  margin-bottom: 0.2rem;
  h5 {
    margin: 0;
    padding: 0;
  }
}

.card-program-events {
  @include callout-base;
  background-color: $light-gray;
  border: none;
  padding: 1rem;
  margin-bottom: 1.5rem;
  h5 {
    margin: 0;
    padding: 0;
  }
}

.callout-medium-gray {
  @include callout-base;
  background-color: lighten($medium-gray,50%);
  border: none;
  padding: 0.5rem 1rem;
  margin-bottom: 0.2rem;
  h5 {
    margin: 0;
    padding: 0;
  }
}

.callout-orange {
  @include callout-base;
  background-color: map-get($foundation-palette, primary);
  color: $white;
  border: none;
  padding: 0.5rem 1rem;
  margin-bottom: 0.2rem;
}

.accordion-program {
  @include accordion-container;
  padding: 0;
  color: $white;

  //The accordion is closed
  .accordion-title:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f078';
    color: $white;
    font-weight:900;
  }
  //The accordion is open
  .is-active .accordion-title:before {
    font-family: 'Font Awesome 5 Free';
    content: '\f077';
    color: $white;
    font-weight:900;
  }
  li.accordion-item {
    @include accordion-item;
    margin: 0;
    padding: 0;
    background-color: $white;
    a.accordion-title {
      padding: 0.5rem 1rem;
      margin-bottom: 0.2rem;
      background-color: map-get($foundation-palette, primary);
      font-size: 20px;
      font-weight: 400;
      h5 {
        color: $white;
        margin: 0;
        padding: 0;
      }
    }
  }
}

a img.program-image {
  max-height: 113px !important;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  aspect-ratio: 16 / 9;
}

#modal_pressebilder_download {
  p.small {
    font-size:14px; }
}
