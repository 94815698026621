@import 'settings';
// font-family: 'Open Sans', sans-serif;
body {
  word-wrap: break-word;
  overflow-x: hidden;
}
// Headerfonts

h1,
h2,
h3,
.h1,
.h2,
.h3 {
  font-weight: 300;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
  font-weight: 400;
}
h6,
.h6 {
  margin-bottom: 0.25rem;
}

strong {
  font-weight: 500;
}

p {
  font-weight: 300;
  margin-bottom: 1.5rem;
}

// Hotizontal Line

.hr-white  {
  margin-top: 0;
  border-color: $white;
}

.hr-gray  {
  margin-top: 0;
  border-color: $dark-gray;
}

.hr-orange  {
  margin-top: 0;
  border-color: $primary-color;;
}

.home-title {
  color: $primary-color;
  font-family: 'Delius', cursive;
  margin: 0 0 2.5rem 0;
  line-height: 1.2;
  text-align: center;
}

.site-title {
  color: $primary-color;
  font-family: 'Delius', cursive;
  margin: 0 0 2.5rem 0;
  line-height: 1.2;
}

.content-title {
  color: $primary-color;
  font-family: 'Delius', cursive;
  margin-bottom: 0;
  line-height: 1.2;
}

ul.menu {
  &.vertical li {
    margin: 0;
  }
  list-style: none;
  padding: 0;
  word-wrap: normal;
  a {
    color: $dark-gray;
    &:hover {
      color: $primary-color;
    }
  }
}


ul.custom-list {
  list-style: none;
  li {
    margin: 0 0 0.5rem 0.4rem;
  }
  li:before {
    content: '\f111'; /* FontAwesome Unicode */
    font-family: 'Font Awesome 5 Free';
    font-size: 0.4em;
    display: inline-block;
    vertical-align: middle;
    color: lighten($primary-color, 20%);
    font-weight: 700;
    margin-left: -2.6em; /* same as padding-left set on li */
    margin-right: 1.5em; /* same as padding-left set on li */
    margin-top: -0.3em; /* same as padding-left set on li */
  }
}

#nav {
  @include breakpoint(medium down) {
    padding: 0;
    margin: 0;
  }
}

.title-bar {
  width: 100%;
  max-width: 100%;
  background-color: $primary-color;
}

.top-bar {
  .top-bar-left {
    width: 225px;
    max-width: 225px;
    @include breakpoint(medium down) {
      // width: 150px;
      // max-width: 150px;
      // padding-top: 26px;
      display: none;
    }
  }
  .top-bar-right {
    padding: 56px 0 0 0;
    height: 100%;
    @include breakpoint(large down) {
      padding: 60px 0 0 0;
    }
    @include breakpoint(medium down) {
      padding: 0;
      margin-left: 0;
    }
    ul {
      li {
        margin-bottom: 0;
      }
      li a {
        font-size: 20px;
        font-weight: 400;
        @include breakpoint(large down) {
          padding: 0.7rem 0.5rem;
        }
        @include breakpoint(medium down) {
          padding: 0.7rem 1rem;
        }
      }
    }
  }
}

#header {
//  max-height: 650px;
  img {
    display: block;
    width: 100%; /* just add width in case you want the image covering full-width */
    max-height: 50vh;
    margin-bottom: 2rem;
    @include breakpoint(medium down) {
      margin-bottom: 1rem;
    }
    @include breakpoint(small down) {
      margin-bottom: 0rem;
    }
    object-fit: cover;
    @include breakpoint(small down) {
      // display: none;
    }
  }
  .orbit {
    @include breakpoint(small down) {
      // display: none !important;
    }
  }
}

#footer-1 {
  &.kater-matze {
    background-color: #06364b;
  }
  background-color: map-get($foundation-palette, secondary);
  color: lighten(map-get($foundation-palette, secondary), 50%);
  .grid-x {
    .cell p {
      font-size: 14px;
      font-weight: normal;
      margin-bottom: 1rem;
      @include breakpoint(medium down) {
        font-size: 12px;
      }
      @include breakpoint(small down) {
        font-size: 14px;
      }
    }
    color: lighten(map-get($foundation-palette, secondary), 50%);
    padding: 3rem 0;
    ul.menu {
      li {
        margin: 0;
        a {
          font-size: 32px;
          font-weight: 400;
          // line-height: 1;
          padding-left: 0;
          color: lighten(map-get($foundation-palette, secondary), 50%);
          @include breakpoint(medium down) {
            font-size: 22px;
          }
        }
        &.is-active a {
          color: $primary-color;
        }
        }
    }
    i.fab {
      margin-right: 1rem;
      color: lighten(map-get($foundation-palette, secondary), 50%);
      &:hover {
        color: $primary-color;
      }
    }
    .small-12:last-child{
      @include breakpoint(small down) {
        margin-top: 3rem;
      }
    }
  }
}

#footer-2 {
  &.kater-matze {
    background-color: #051d33;
  }
  background-color: $dark-gray;
  .grid-x {
    padding: 0;
    p {
      font-size: 12px;
      color: lighten(map-get($foundation-palette, secondary), 50%);
      margin: 8px 0;
    }
  }
}

.schmuck-font {
  font-family: 'Delius', cursive;
}

// -----------
// Cards
// ----------

.card-mini-musik {
  max-height: 400px;
  position: relative;
  @include card-container($light-gray, none,$grid-column-gutter, none, none, none);
  padding: 0;
  a img {
    height: 12.5rem;
    max-height: 12.5rem;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }
  i.fas.fa-long-arrow-alt-right {
    font-size: 16px;
    margin: 11rem 1rem 0 0;
    float: right;
    color: $primary-color;
  }
  .card-section-mini-musik {
    @include card-section;
    height: 12.5rem;
    padding: 1rem 1.2rem;
    // padding: 1rem 1.2rem 0 1.2rem;
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.card-blog-post {
  @include card-container($light-gray, none,$grid-column-gutter, none, none, none);
  padding: 0;
  a img {
    height: 15rem;
    max-height: 15rem;
    width: 100%;
    object-fit: cover;
  }
  i.fas.fa-long-arrow-alt-right {
    font-size: 16px;
    margin: 13rem 1rem 0 0;
    float: right;
    color: $primary-color;
  }
  .card-section-blog-post {
    @include card-section;
    height: 15rem;
    padding: 1rem 1.2rem;
    // padding: 1rem 1.2rem 0 1.2rem;
    p {
      margin-bottom: 0.5rem;
    }
  }
}

.columns.video {
  padding:0;
  background-color: $light-gray;
  .video-text {
    margin: 0 1rem;
  }
}

// ---------------
// Video Container
// ---------------
.container {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.input.error {
  label {
    @extend .is-invalid-label;
  }

  input,
  textarea,
  select {
    @extend .is-invalid-input;
  }

  small.error {
    @extend .form-error;
    @extend .is-visible;
  }
}
